import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import ExperimentPage from './experiment/[id]'
import {graphql } from 'gatsby';

const Experiment = () => {
  return (
    <Layout>
      <Router basepath="/experiment">
        <ExperimentPage path={':id'} />
      </Router>
      <Router basepath="/:lang/experiment">
        <ExperimentPage path={':id'} />
      </Router>
    </Layout>
  )
}

export default Experiment

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","index", "experiment", "form_errors", "games"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;